import React, { useContext } from "react";
import { DataContext } from "../../contexts/dataContext";
import { Link } from "react-router-dom";

const DestinationCard = ({
  id,
  pic_folder,
  pic_file_name,
  title,
  card_text,
}) => {
  const { state, setSelectedDest } = useContext(DataContext);
  return (
    <div className="col">
      <div className="card h-100 custom-card-transition">
        <img
          src={`${pic_folder}/${pic_file_name}`}
          className="card-img-top"
          alt={title}
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>

          <p className="card-text">
            <span
              dangerouslySetInnerHTML={{
                __html: card_text,
              }}
            />
          </p>
          <Link
            to="/destinations"
            className="btn custom-button"
            onClick={() => setSelectedDest(id, title)}
          >
            Learn More{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DestinationCard;
