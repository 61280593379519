import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/festivals.css";
import { getBlogCategories } from "../../apis/blogApis";
import { DataContext } from "../../contexts/dataContext";
import { Link } from "react-router-dom";

const BlogCategories = () => {
  const [blogCategories, setBlogCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { setSelectedBlogCategory } = useContext(DataContext);

  const fetchBlogCategories = async () => {
    try {
      const { data } = await getBlogCategories();
      // console.log(data.blogCategories);
      setBlogCategories(data.blogCategories);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchBlogCategories().finally(() => setLoading(false));
  }, []);

  return (
    <div className="col">
      <div className="card shadow custom-card">
        <div className="card-header text-center">
          <span>Blog Categories</span>
        </div>
        <div className="card-body">
          <div>
            <ul className="list-unstyled mb-0">
              {blogCategories.map((category) => (
                <li key={category.id}>
                  <Link
                    // type="button"
                    className="btn btn-link btn-link-sm custom-link"
                    onClick={() =>
                      setSelectedBlogCategory(category.id, category.name)
                    }
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCategories;
