import { Link } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../contexts/dataContext";

const Header = () => {
  const {
    setDestinationHome,
    setFestivalofTheMonth,
    setCultureSpotLight,
    setEventofTheMonth,
    setRecentBlog,
  } = useContext(DataContext);

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top app-bg-primary">
        <div className="container">
          <Link className="navbar-brand" to="/">
            Keralatour.com
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/about-kerala">
                  About Kerala
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={setDestinationHome}
                  to="/destinations"
                >
                  Destinations
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownPortfolio"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Discover Kerala
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdownPortfolio"
                >
                  <Link
                    className="dropdown-item"
                    onClick={setCultureSpotLight}
                    to="/culture"
                  >
                    Arts and Culture
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={setFestivalofTheMonth}
                    to="/festivals"
                  >
                    {" "}
                    Festivals
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={setEventofTheMonth}
                    to="/events"
                  >
                    Events
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/packages">
                  Packages
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" onClick={setRecentBlog} to="/blogs">
                  Travelogues
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about-us">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
