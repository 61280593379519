import apiHub from "./apiHub";

export async function getPopularDestinations() {
  // console.log("getPopularDestinations");
  return await apiHub.get("/popular-destinations");
}

export async function getAllDestinations() {
  return await apiHub.get("/all-destinations");
}

export async function getDestinationById(itemId) {
  return await apiHub.get("/destination", { params: itemId });
}

export async function getDestinationInfo(itemId) {
  return await apiHub.get("/destination-info", { params: itemId });
}

export async function getDestinationAttractions(itemId) {
  return await apiHub.get("/destination-attractions", { params: itemId });
}

export async function getDistricts() {
  return await apiHub.get("/districts");
}

export async function getDestinationThemes() {
  return await apiHub.get("destination-themes");
}

export async function getDestinationByTheme(themeId) {
  return await apiHub.get("/destination-by-theme", { params: themeId });
}
