import { useEffect, useState, useContext } from "react";
import { DataContext } from "../../contexts/dataContext";
import {
  getDestinationById,
  getDestinationInfo,
  getDestinationAttractions,
} from "../../apis/destinationApis";
import { groupBy } from "../utils/groupby";

const DestinationDetails = () => {
  const { state } = useContext(DataContext);
  const {
    selected: { destId, destName },
  } = state;

  // console.log("DestinationDetails state:", state);

  const [destination, setDestination] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attractions, setAttractions] = useState(null);
  const [groupedInfo, setGroupedInfo] = useState(null);

  const fetchDestinationById = async () => {
    try {
      const { data } = await getDestinationById({ id: destId });
      // console.log(data.destination);
      setDestination(data.destination);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  const fetchDestinatioInfo = async () => {
    try {
      const { data } = await getDestinationInfo({ id: destId });
      // console.log("info", data.destInfo);
      const groups = groupBy(data.destInfo, "section");
      setGroupedInfo(groups);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  const fetchDestinatioAttractions = async () => {
    try {
      const { data } = await getDestinationAttractions({ id: destId });
      // console.log(data.attractions);
      setAttractions(data.attractions);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDestinationById();
    fetchDestinatioInfo();
    fetchDestinatioAttractions().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  return (
    <div>
      {destination && (
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-lg-12">
                <img
                  className="img-fluid rounded"
                  src={`/${destination.pic_folder}/${destination.pic_file_name}`}
                  alt={destination.name}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="my-4">
                  <h1>{destination.title}</h1>
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: destination.long_desc,
                      }}
                    />
                  </p>
                </div>
              </div>
              {groupedInfo && Object.keys(groupedInfo).length > 0 && (
                <div className="p2">
                  {Object.entries(groupedInfo).map(([section, infos]) => (
                    <div key={section}>
                      <h5 className="mt-2 text-uppercase title-bold-500">
                        {section}
                      </h5>
                      {infos.map((info) => (
                        <div key={info.title}>
                          <p>
                            <span className="title-bold-500 fw-bold">
                              {info.title}:&nbsp;
                            </span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: info.description,
                              }}
                            ></span>
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            {attractions && attractions.length > 0 && (
              <div className="card shadow custom-card">
                <div className="card-header text-center">
                  <span>Nearby Attractions</span>
                </div>
                <div className="card-body">
                  {attractions.map((attraction) => (
                    <div key={attraction.id} className="row">
                      <h4 className="mt-2 text-uppercase title-sidebox">
                        {attraction.title}
                      </h4>
                      <p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: attraction.description,
                          }}
                        />
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DestinationDetails;
