import { createContext, useState, useEffect } from "react";

const DataContext = createContext();

const resetState = () => ({
  selected: {
    destId: null,
    destName: null,
    festivalId: null,
    districtId: null,
    districtName: null,
    cultureId: null,
    cultureCategoryId: null,
    cultureCategory: null,
    eventtId: null,
    eventCategoryId: null,
    eventCategory: null,
    blogId: null,
    blogCategoryId: null,
    blogCategory: null,
    month: null,
  },
  views: {
    destinationsList: false,
    destinationDetails: false,
    festivalOfTheMonth: false,
    festivalList: false,
    festivalDetails: false,
    cultureSpotLight: false,
    cultureList: false,
    cultureDetails: false,
    eventOfTheMonth: false,
    eventList: false,
    eventDetails: false,
    recentBlog: false,
    blogList: false,
    blogDetails: false,
  },
});

const DataProvider = ({ children }) => {
  const [state, setState] = useState(resetState); // store data in the context

  const setDestinationHome = () => {
    setState((prevState) => ({
      ...prevState,
      ...resetState(),
      views: {
        ...resetState().views,
        destinationsList: true,
      },
    }));
  };

  const setSelectedDest = (id, name) => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        destId: id,
        destName: name,
      },
      views: {
        ...resetState().views,
        destinationDetails: true,
      },
    }));
  };

  const setFestivalofTheMonth = () => {
    setState((prevState) => ({
      ...prevState,
      ...resetState(),
      views: {
        ...resetState().views,
        festivalOfTheMonth: true,
      },
    }));
  };

  const setSelectedFestival = (id) => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        festivalId: id,
      },
      views: {
        ...resetState().views,
        festivalDetails: true,
      },
    }));
  };

  const setSelectedDistrict = (id, name, view) => {
    // console.log("selected district", id, name, view);
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        districtId: id,
        districtName: name,
      },
      views: {
        ...resetState().views,
        [`${view}List`]: true,
      },
    }));
  };

  const setSelectedMonth = (monthName, view) => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        month: monthName,
      },
      views: {
        ...resetState().views,
        [`${view}List`]: true,
      },
    }));
  };

  const setCultureSpotLight = () => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
      },
      views: {
        ...resetState().views,
        cultureSpotLight: true,
      },
    }));
  };

  const setSelectedCultureCategory = (categoryId, category_name) => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        cultureCategoryId: categoryId,
        cultureCategory: category_name,
      },
      views: {
        ...resetState().views,
        cultureList: true,
      },
    }));
  };

  const setSelectedCulture = (cultureId) => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        cultureId: cultureId,
      },
      views: {
        ...resetState().views,
        cultureDetails: true,
      },
    }));
  };

  const setEventofTheMonth = () => {
    setState((prevState) => ({
      ...prevState,
      ...resetState(),
      views: {
        ...resetState().views,
        eventOfTheMonth: true,
      },
    }));
  };

  const setSelectedEvent = (id) => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        eventId: id,
      },
      views: {
        ...resetState().views,
        eventDetails: true,
      },
    }));
  };

  const setRecentBlog = () => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
      },
      views: {
        ...resetState().views,
        recentBlog: true,
      },
    }));
  };

  const setSelectedBlogCategory = (categoryId, category_name) => {
    // console.log("selected blog category", categoryId, category_name);
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        blogCategoryId: categoryId,
        blogCategory: category_name,
      },
      views: {
        ...resetState().views,
        blogList: true,
      },
    }));
    // console.log("selected blog category", state);
  };

  const setSelectedBlog = (id) => {
    // console.log("selected blog", id);
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...resetState().selected,
        blogId: id,
      },
      views: {
        ...resetState().views,
        blogDetails: true,
      },
    }));
  };

  return (
    <DataContext.Provider
      value={{
        state,
        setState,
        setDestinationHome,
        setSelectedDest,
        setSelectedDistrict,
        setFestivalofTheMonth,
        setSelectedMonth,
        setSelectedFestival,
        setCultureSpotLight,
        setSelectedCultureCategory,
        setSelectedCulture,
        setEventofTheMonth,
        setSelectedEvent,
        setRecentBlog,
        setSelectedBlogCategory,
        setSelectedBlog,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
