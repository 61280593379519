import React from "react";
import { useEffect } from "react";
import { getRecentBlog } from "../../apis/blogApis";
import Layout from "../layout/layout";
import { Link } from "react-router-dom";
import { formatIsoDate } from "../utils/dateFunctions";

const RecentBlog = () => {
  const [blog, setBlog] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  // Get a recent Blog
  const fetchRecentBlog = async () => {
    // console.log("fetchRecentBlog called");
    try {
      const { data } = await getRecentBlog();
      // console.log(data);
      if (data && data.rowCount > 0) {
        setBlog(data.recentBlog);
        // console.log(data.recentBlog);
      } else {
        setBlog({});
      }
    } catch (error) {
      const status = error.response?.status;
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchRecentBlog().finally(() => setLoading(false));
  }, []);

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <article className="blog-post">
      <div className="row">
        {blog && (
          <div className="col-12">
            <h2 className="mb-1">{blog.blog_title}</h2>
            <p className="blog-post-meta">
              Published on: {formatIsoDate(blog.posted_on_dt)}
            </p>
            <p className="blog-post-meta">
              <span className="h5">By {blog.posted_by}</span>{" "}
            </p>
            <div style={{ float: "right", clear: "right" }}>
              <img
                className="img-fluid rounded ml-2"
                src="assets/images/blog/{{blog.pic_file_name}}"
                alt={blog.pic_caption}
              />
            </div>

            <p>{blog.postedOnDt}</p>

            <p>
              {" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: blog.blog_text,
                }}
              />
            </p>

            <hr />
          </div>
        )}
      </div>
    </article>
  );
};

export default RecentBlog;
