import React, { useEffect, useState, useContext } from "react";
import { getEventById } from "../../apis/eventApis";
import { DataContext } from "../../contexts/dataContext";
import { displayJSONInfo } from "../utils/displayJSONInfo";
import { formatIsoDate } from "../utils/dateFunctions";

const EventDetails = () => {
  const { state } = useContext(DataContext);
  const {
    selected: { eventId },
  } = state;

  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchEventDetails = async () => {
    try {
      const { data } = await getEventById(eventId);
      setEvent(data.event);
      console.log(data.event);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEventDetails().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }
  return (
    <div className="row">
      {event && (
        <div className="col-md-12">
          <div className="mb-4">
            <img
              className="img-fluid rounded"
              src={`${event.pic_folder}/${event.pic_file_name}`}
              alt={event.name}
            />
          </div>
          <h3>
            <span className="mb-2">{event.event_name}</span>
          </h3>
          <ul>
            <li className="li-gap">
              <span className="li-10em">When is it happening:</span>{" "}
              {event.from_dt === event.to_dt
                ? formatIsoDate(event.from_dt)
                : `${formatIsoDate(event.from_dt)} - ${formatIsoDate(
                    event.to_dt
                  )}`}
            </li>
            <li className="li-gap">
              <span className="li-10em">Where is it happening:</span>{" "}
              {event.location}
            </li>
            <li className="li-gap">
              <span className="li-10em">Main Attraction: </span>
              {event.short_desc}
            </li>
          </ul>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: event.short_desc,
              }}
            />
          </p>
          <p>
            <div>
              {event &&
                event.general_info &&
                displayJSONInfo(event.general_info)}
            </div>
          </p>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
