// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
import "../../assets/styles/carousel.css";
// register Swiper custom elements
register();

const Carousel = ({ images }) => {
  return (
    <swiper-container
      navigation="true"
      pagination="true"
      slides-per-view="1"
      speed="500"
      loop="true"
      css-mode="true"
      // autoplay="true"
      // autoplaySpeed={5000}
    >
      {images.map((image, index) => (
        <swiper-slide key={index}>
          <img
            className="d-block w-100 carousel-img"
            src={image}
            alt={`Slide ${index}`}
          />
          <div className="h1 carousel-caption">
            God's Own Country awaits you!
          </div>
        </swiper-slide>
      ))}
    </swiper-container>
  );
};

export default Carousel;
