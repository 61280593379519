import React, { useEffect, useState, useContext } from "react";
import Layout from "../layout/layout";
import { getCultureById } from "../../apis/cultureApis";
import { DataContext } from "../../contexts/dataContext";
import { displayJSONInfo } from "../utils/displayJSONInfo";

const CultureDetails = () => {
  const { state } = useContext(DataContext);
  const {
    selected: { cultureId },
  } = state;

  const [culture, setCulture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCultureDetails = async () => {
    try {
      const { data } = await getCultureById(cultureId);
      setCulture(data.culture);
      // console.log(data.culture);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchCultureDetails().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Layout>
        <div className="container">Loading...</div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="container">Error: {error}</div>
      </Layout>
    );
  }
  return (
    <div className="row">
      {culture && (
        <div className="col-md-12">
          <div className="mb-4">
            <img
              className="img-fluid rounded"
              src={`${culture.pic_folder}/${culture.pic_file_name}`}
              alt={culture.name}
            />
          </div>
          <h3>
            <span className="mb-2">{culture.title}</span>
          </h3>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: culture.long_desc,
              }}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default CultureDetails;
