import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout/layout";

const AboutUs = () => {
  return (
    <Layout>
      <div className="container my-5">
        <section>
          <div className="row">
            <div className="col-lg-8">
              <h2>Our mission</h2>
              <p>
                Connect travelers to the people and places they love, giving
                them both a platfrom needed to create memories that last a
                lifetime.
              </p>
              <h2>About KeralaTour</h2>
              <p>
                First of its kind in the history of Eco Tourism (Nature Friendly
                Tourism), we organized and conducted a Canoe challenge and
                adventure tour for a group of fifteen young people from Brent,
                England. They canoed from Kochin to Kollam in our country,
                covering a distance of more than 200 Kilometers (about 115
                miles) in fifteen days, experiencing the landscape known by the
                name "Gods own Country". This conventional tour free from air
                pollution, Sound pollution, and five star culture, fetched us
                global reputation right in the year 1996.
              </p>
              <p>
                Today we are re-inventing oursleves as the travel and tourism
                platform for the God's Own country!!{" "}
              </p>
              <h2>What we believe</h2>
              <p>
                The National Geographic Traveler once described Kerala as one of
                the 50 destinations of a life time.
              </p>
              <p>
                We believe that it's not just the places you visit but also the
                people you meet that make your experience complete. We bring the
                passionate people of the Kerala travel industry and connect them
                with travelers around the world rooted in the age old Indian
                Philosophy of "Atithi Devo Bhava" - Guest is God!!
              </p>
              <p>
                Welcome to Keralatour, bringing you Kerala in all it's glory for
                the experience of a lifetime.
              </p>
            </div>

            <div className="col-lg-4">
              <hr />
              <div className="card-text mb-3">
                <h3>For Media Inquiries:</h3>{" "}
                <Link to="/contact-us">Contact Us</Link>
              </div>
              <div className="card-text">
                <a
                  href="https://twitter.com/keralatour3?ref_src=twsrc%5Etfw"
                  target="_blank"
                  className="twitter-follow-button"
                  data-show-count="false"
                >
                  Follow @keralatour
                </a>
                <script
                  async
                  src="https://platform.twitter.com/widgets.js"
                  charset="utf-8"
                ></script>
              </div>
              <hr />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;
