import { useEffect, useState } from "react";
import { getCultureSpotLight } from "../../apis/cultureApis";
import { displayJSONInfo } from "../utils/displayJSONInfo";

const CultureSpotLight = () => {
  const [culture, setCulture] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchCultureSpotLight = async () => {
    try {
      const { data } = await getCultureSpotLight();
      setCulture(data.culture);
      // console.log(data.culture);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchCultureSpotLight().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  return (
    <div className="row">
      {culture && (
        <div className="col-md-12">
          <div className="mb-4">
            <img
              className="img-fluid rounded"
              src={`${culture.pic_folder}/${culture.pic_file_name}`}
              alt={culture.title}
            />
          </div>
          <h3>
            <span className="mb-2">{culture.title}</span>
          </h3>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: culture.long_desc,
              }}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default CultureSpotLight;
