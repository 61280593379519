import apiHub from "./apiHub";

export async function getEventOfTheMonth() {
  return await apiHub.get("/event-of-the-month");
}

export async function getAllEvents() {
  return await apiHub.get("/all-events");
}

export async function getEventsByMonth(month) {
  // console.log("getEventsByMonth called");
  return await apiHub.get("/events-by-month", { params: { month } });
}

export async function getEventById(id) {
  return await apiHub.get("/event", { params: { id } });
}

export async function getEventsByDistrict(districtId) {
  // console.log("getEventsByDistrict called");
  return await apiHub.get("/events-by-district", { params: { districtId } });
}
