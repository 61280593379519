import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout/layout";

const Terms = () => {
  return (
    <Layout>
      <section className="container my-5">
        <div className="row">
          <div className="col-lg-8">
            <h2>
              The following terms and conditions apply to the use of this
              website.
            </h2>

            <p>
              The material provided on this site is for informational purpose
              only and is free of charge. Although best efforts were made in
              making the content on this website as useful, accurate, and
              up-to-date as possible, KeralaTour.com cannot guarantee, warrant,
              or make any representations regarding the correctness, accuracy,
              or reliability of the material presented.{" "}
            </p>

            <p>
              Information on this Web site may contain technical inaccuracies or
              typographical errors. In no event will KeralaTour.com be held
              responsible or liable for any consequential, incidental, direct or
              indirect damages occurring from the use of this web site . The
              entire risk resulting out of the use of the contents of this site
              is assumed by the user.{" "}
            </p>

            <p>
              Unless otherwise stated, the contents of this site including, but
              not limited to, the text and images are the property of
              KeralaTour.com. All trademarks used or referred to in this website
              are the property of their respective owners.{" "}
            </p>

            <p>
              Please note that any information submitted through this site will
              be deemed NOT to be confidential. However, we will not release
              your name or any other information with out first contacting you
              and getting your approval. More information on KeralaTour.com's
              privacy policy can be found at privacy policy.{" "}
            </p>

            <p>
              This site and the content provided in this site, may not be
              copied, reproduced, distributed or transmitted in any way, without
              the prior written consent of KeralaTour.com
            </p>
          </div>

          <div className="col-lg-4">
            <hr />
            <div className="card-text mb-3">
              <h3>For Inquiries:</h3> <Link to="/inquiry">Contact Us </Link>
            </div>
            <div className="card-text">
              <Link
                href="https://twitter.com/keralatour3?ref_src=twsrc%5Etfw"
                target="_blank"
                className="twitter-follow-button"
                data-show-count="false"
              >
                Follow @keralatour
              </Link>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charset="utf-8"
              ></script>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Terms;
