import React from "react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import { Link } from "react-router-dom";
import { getCultureByCategory } from "../../apis/cultureApis";

const CultureList = () => {
  const { state, setSelectedCulture } = useContext(DataContext);
  // console.log(data);
  const {
    selected: { cultureCategoryId, cultureCategory },
  } = state;
  const [culture, setCulture] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchCulture = async () => {
    try {
      const response = await getCultureByCategory(cultureCategoryId);
      // console.log(response);
      setCulture(response.data.culture);
      // console.log(culture);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setError("error fetching data" + error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchCulture().finally(() => setLoading(false));
  }, [state]);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  if (culture && culture.length === 0) {
    return (
      <div className="container text-center py-5">
        <h1 className="display-6 text-secondary">
          Nothing found for the selected filter
        </h1>
        <p className="lead text-muted">
          We are in the process of updating the latest events. Please check back
          later.
        </p>
      </div>
    );
  }

  return (
    <div className="row g-2">
      <h2>{cultureCategory} </h2>
      {culture &&
        culture.map((culture) => (
          <article className="col-12 mb-0" key={culture.id}>
            <div className="card mb-4 rounded-5 shadow-sm h-shadow custom-card-transition">
              <div className="row no-gutters">
                <div className="col-md-4 mb-md-0 d-flex align-items-center justify-content-center">
                  <img
                    src={`${culture.pic_folder}/${culture.pic_file_name}`}
                    alt={culture.fest_name}
                    className="img-thumbnail rounded-3 object-cover border-0"
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <Link onClick={() => setSelectedCulture(culture.id)}>
                      {culture.title}
                    </Link>
                    <p className="card-text">
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: culture.card_text,
                        }}
                      />
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="btn-group">
                        <button
                          onClick={() => setSelectedCulture(culture.id)}
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
    </div>
  );
};

export default CultureList;
