import React from "react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import {
  getFestivalsByDistrict,
  getFestivalsByMonth,
} from "../../apis/festivalApis";
import { Link } from "react-router-dom";

const FestivalList = () => {
  const { state, setSelectedFestival } = useContext(DataContext);
  // console.log(data);
  const {
    selected: { districtId, districtName, month },
  } = state;
  const [festivals, setFestivals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchFestivals = async () => {
    let response = null;
    try {
      if (districtId) {
        response = await getFestivalsByDistrict(districtId);
      } else if (month) {
        response = await getFestivalsByMonth(month);
      }
      // console.log(response);
      setFestivals(response.data.festivals);
      // console.log(festivals);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setError("error fetching data" + error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchFestivals().finally(() => setLoading(false));
  }, [state]);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  if (festivals && festivals.length === 0) {
    return (
      <div className="text-center p-5">
        <h1 className="display-7 text-secondary">
          No Festivals for the selected filter
        </h1>
        <p className="lead text-muted">
          We are in the process of updating the latest information. Please check
          back later.
        </p>
      </div>
    );
  }

  return (
    <div className="row g-2">
      <h2>
        Festivals in {month} {districtName}
      </h2>
      {festivals &&
        festivals.map((festival) => (
          <article className="col-12 mb-0" key={festival.id}>
            <div className="card mb-4 rounded-5 shadow-sm h-shadow custom-card-transition">
              <div className="row no-gutters">
                <div className="col-md-4 mb-md-0 d-flex align-items-center justify-content-center">
                  <Link onClick={() => setSelectedFestival(festival.id)}>
                    <img
                      src={`${festival.pic_folder}/${festival.pic_file_name}`}
                      alt={festival.fest_name}
                      className="img-thumbnail rounded-3 object-cover border-0"
                    />
                  </Link>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <Link onClick={() => setSelectedFestival(festival.id)}>
                      {festival.fest_name}
                    </Link>

                    <p className="card-text">{festival.fest_dates}</p>
                    <p className="card-text">
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: festival.main_attraction,
                        }}
                      />
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="btn-group">
                        <button
                          onClick={() => setSelectedFestival(festival.id)}
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
    </div>
  );
};

export default FestivalList;
