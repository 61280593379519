import Layout from "../components/layout/layout";
import { getAboutKerala } from "../apis/aboutKeralaApis";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { decode } from "html-entities";
import { Link } from "react-router-dom";

const MainContent = ({ content }) => {
  return (
    <div className="container">
      {content.topic === "General Info" && (
        <div style={{ float: "right", clear: "right" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4016734.7527726707!2d73.88381004802422!3d10.532724200376261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0812ffd49cf55b%3A0x64bd90fbed387c99!2sKerala%2C%20India!5e0!3m2!1sen!2sus!4v1590262911181!5m2!1sen!2sus"
            width="420"
            height="315"
            frameborder="0"
            style={{ border: "0" }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
            className="ml-2"
          ></iframe>
        </div>
      )}
      {content.pic_file_name && (
        <div style={{ float: "right", clear: "right" }}>
          <img
            className="img-fluid rounded ml-2 custom-image"
            src={`assets/images/about-kerala/${content.pic_file_name}`}
            alt={content.pic_caption}
            width="420"
          />
        </div>
      )}
      <div
        style={{
          float: "right",
          width: "0px",
          padding: "5px",
          height: "200px",
        }}
      ></div>
      <div>
        <h2>{content.title}</h2>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content.long_desc),
          }}
        ></span>
      </div>
    </div>
  );
};

const SideContent = ({ data, handleTopicClick }) => {
  return (
    <div className="card shadow custom-card">
      <div className="card-header text-center">
        <span>About Kerala</span>
      </div>
      <div className="card-body">
        {data.map((item) => (
          <div>
            <ul className="list-unstyled">
              <li className="li-gap">
                <Link
                  className="custom-link"
                  onClick={() => handleTopicClick(item.topic)}
                >
                  <span>{item.topic}</span>
                </Link>
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

const AboutKerala = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState({});

  const handleTopicClick = (topic) => {
    const newData = data.filter((item) => item.topic === topic)[0];
    setContent(newData);
  };

  const fetchData = async () => {
    try {
      const { data } = await getAboutKerala();
      setData(data.aboutKerala);
      // console.log(data.aboutKerala);
      const content = data.aboutKerala.filter(
        (items) => items.topic === "General Info"
      )[0];
      // console.log(content);
      setContent(content);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Layout>
        <div className="container">Loading...</div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="container">Error: {error}</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <section className="container my-5">
        <div className="row p-4">
          <div className="col-md-9">
            <MainContent content={content} />
          </div>
          <div className="col-md-3">
            <SideContent data={data} handleTopicClick={handleTopicClick} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutKerala;
