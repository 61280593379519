import Layout from "../components/layout/layout";
import { useContext } from "react";
import { DataContext } from "../contexts/dataContext";
import DestinationDetails from "../components/destinations/destinationDetails";
import Destinations from "../components/destinations/destinations";

const DestinationsHome = () => {
  const { state } = useContext(DataContext);
  // console.log("DestinationHome state:", state);
  const {
    views: { destinationsList, destinationDetails },
  } = state;

  return (
    <Layout>
      <section className="container my-5">
        <div className="row g-4">
          <div className="col-md-12">
            {destinationsList && <Destinations />}
            {destinationDetails && <DestinationDetails />}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DestinationsHome;
