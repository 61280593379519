import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout/layout";

const Privacy = () => {
  return (
    <Layout>
      <section className="container my-5">
        <div className="row">
          <div className="col-lg-8">
            <p>
              We at <strong>KeralaTour.com</strong> primarily collect
              information about you to understand you better and to meet your
              exacting requirements about what KeralaTour.com should be all
              about. Our aim is to provide you a personalized experience!
            </p>

            <h2>We collect information about you...</h2>

            <p>
              We would collect information from you primarily during the
              following uses of the Web Site:
            </p>
            <ul>
              <li>
                When registering for certain services such as personalization,
                chat, email, community pages, contests and promotions.
              </li>
              <li>When providing us feedback on our feedback form.</li>
              <li>
                When you opt to receive e-mail or other communications from us.
              </li>
              <li>
                Any other service which requires you to provide personal
                information.
              </li>
            </ul>

            <h2>Your consent</h2>
            <p>
              You agree that any information that you may provide us with regard
              to yourself (such as e-mail address, postal address, personal
              preferences, financial information, etc) is at your free and
              voluntary will.
            </p>

            <h2>Use of information by us</h2>
            <p>
              This information will primarily be used for the following
              purposes:
            </p>
            <ul>
              <li>
                Providing customization [and verification]. Our agents or we may
                use the information provided by you to customize your visit to
                the Web Site by displaying appropriate content at our judgement
                and discretion.
              </li>
              <li>
                Send you information about us and our partners in providing you
                the Web Site.
              </li>
              <li>To contact you when required.</li>
            </ul>

            <h2>Use of information by Third Parties</h2>
            <p>
              We may also provide the information collected from you to Third
              Parties (including our partners in providing you with the services
              on the Web Site). The Third Parties would be entitled to use the
              information for preparing their business plans, forecasts, etc and
              may forward you promotional material. We disclaim any liability
              for any loss, injury, and damage or otherwise caused to you due to
              the use of information by Third Parties.
            </p>

            <p>
              You may expressly request us to forward your personal information
              to any Third Party providing services on our Web Site (including
              auction organizers, contest sponsors, etc). We may use devices to
              record and track the pages that you have visited along with the
              personal information that you have provided. You may wish to
              eliminate the use of these devices if you have customized your
              browser.
            </p>

            <h2>Your option to discontinue</h2>
            <p>
              You may opt not to receive any further information or promotional
              literature from us or any Third Party providing services on the
              Web Site. Upon such request from you, we shall discontinue the use
              of information provided by you about yourself for the purpose of
              sending further information to you. We shall also not provide such
              information to any Third Party from the date of your request for
              discontinuance. However, we shall not be liable for the use of any
              information provided by you which we have already divulged to
              Third Parties, prior to receiving your request for discontinuance.
            </p>
          </div>

          <div className="col-lg-4">
            <hr />
            <div className="card-text mb-3">
              <h3>For Media Inquiries:</h3>{" "}
              <Link to="/inquiry">Contact Us</Link>
            </div>
            <div className="card-text">
              <a
                href="https://twitter.com/keralatour3?ref_src=twsrc%5Etfw"
                target="_blank"
                className="twitter-follow-button"
                data-show-count="false"
              >
                Follow @keralatour
              </a>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charset="utf-8"
              ></script>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Privacy;
