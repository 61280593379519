import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import AboutKerala from "./pages/aboutKerala";
import { DataProvider } from "./contexts/dataContext";
import FestivalsHome from "./pages/festivalsHome";
import CultureHome from "./pages/cultureHome";
import EventsHome from "./pages/eventsHome";
import TourPackages from "./pages/tourPackages";
import AboutUs from "./pages/aboutUs";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Blogs from "./pages/blogs";
import DestinationsHome from "./pages/destinationsHome";
import ContactUs from "./pages/contactUs";
import FAQ from "./pages/faq";

function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/about-kerala" element={<AboutKerala />} />
          <Route path="/destinations" element={<DestinationsHome />} />
          <Route path="/festivals" element={<FestivalsHome />} />
          <Route path="/culture" element={<CultureHome />} />
          <Route path="/events" element={<EventsHome />} />
          <Route path="/packages" element={<TourPackages />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;
