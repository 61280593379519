import Layout from "../components/layout/layout";
import RecentBlog from "../components/blogs/recentBlog";
import DistrictList from "../components/helpers/districtList";
import BlogCategories from "../components/blogs/blogCategories";
import RecentBlogs from "../components/blogs/recentBlogs";
import BlogList from "../components/blogs/blogList";
import { useContext } from "react";
import { DataContext } from "../contexts/dataContext";
import BlogDetails from "../components/blogs/blogDetails";

const Blogs = () => {
  const { state } = useContext(DataContext);
  const {
    views: { recentBlog, blogList, blogDetails },
  } = state;
  return (
    <Layout>
      <div className="container my-5">
        <div className="row g-4">
          <div className="col-md-8">
            {recentBlog && <RecentBlog />}
            {blogList && <BlogList />}
            {blogDetails && <BlogDetails />}
          </div>{" "}
          <div className="col-md-4">
            <BlogCategories />
            <RecentBlogs />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blogs;
