import apiHub from "./apiHub";

export async function getFestivalOfTheMonth() {
  return await apiHub.get("/festival-of-the-month");
}

export async function getAllFestivals() {
  return await apiHub.get("/all-festivals");
}

export async function getFestivalsByMonth(month) {
  // console.log("getFestivalsByMonth called");
  return await apiHub.get("/festivals-by-month", { params: { month } });
}

export async function getFestivalById(id) {
  return await apiHub.get("/festival", { params: { id } });
}

export async function getFestivalsByDistrict(districtId) {
  // console.log("getFestivalsByDistrict called");
  return await apiHub.get("/festivals-by-district", { params: { districtId } });
}
