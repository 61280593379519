import Layout from "../components/layout/layout";
import { useContext } from "react";
import { DataContext } from "../contexts/dataContext";
import CultureSpotLight from "../components/culture/cultureSpotLight";
import CultureCategories from "../components/helpers/cultureCategories";
import CultureList from "../components/culture/cultureList";
import CultureDetails from "../components/culture/cultureDetails";

const CultureHome = () => {
  const { state } = useContext(DataContext);
  // console.log(state);
  const {
    views: { cultureSpotLight, cultureList, cultureDetails },
  } = state;

  return (
    <Layout>
      <section className="container my-5">
        <div className="row g-4">
          <div className="col-md-8">
            {cultureSpotLight && <CultureSpotLight />}
            {cultureList && <CultureList />}
            {cultureDetails && <CultureDetails />}
          </div>
          <div className="col-md-4">
            <CultureCategories />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CultureHome;
