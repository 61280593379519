import { useEffect, useState } from "react";
import { getFestivalOfTheMonth } from "../../apis/festivalApis";
import { displayJSONInfo } from "../utils/displayJSONInfo";

const FestivalOfTheMonth = () => {
  const [festival, setFestival] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchFestivalOfTheMonth = async () => {
    try {
      const { data } = await getFestivalOfTheMonth();
      setFestival(data.festival);
      // console.log(data.festival);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchFestivalOfTheMonth().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  if (festival === null || festival === undefined) {
    return (
      <div className="text-center p-5">
        <h1 className="display-7 text-secondary">
          No Festivals Found for the Current Month
        </h1>
        <p className="lead text-muted">
          We are in the process of updating the latest festivals. Please check
          back later.
        </p>
      </div>
    );
  }

  return (
    <div className="row">
      {festival && (
        <div className="col-md-12">
          <div className="mb-4">
            <img
              className="img-fluid rounded"
              src={`${festival.pic_folder}/${festival.pic_file_name}`}
              alt={festival.name}
            />
          </div>
          <h3>
            <span className="mb-2">{festival.fest_name}</span>
          </h3>
          <ul>
            <li className="li-gap">
              <span className="li-10em">When is it happening:</span>{" "}
              {festival.fest_dates}
            </li>
            <li className="li-gap">
              <span className="li-10em">Where is it happening:</span>{" "}
              {festival.location}
            </li>
            <li className="li-gap">
              <span className="li-10em">Main Attraction: </span>
              {festival.main_attraction}
            </li>
          </ul>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: festival.short_desc,
              }}
            />
          </p>
          <p>
            <div>
              {festival &&
                festival.general_info &&
                displayJSONInfo(festival.general_info)}
            </div>
          </p>
        </div>
      )}
    </div>
  );
};

export default FestivalOfTheMonth;
