import React, { useEffect, useState, useContext } from "react";
import Layout from "../layout/layout";
import { getBlogById } from "../../apis/blogApis";
import { DataContext } from "../../contexts/dataContext";

const BlogDetails = () => {
  const { state } = useContext(DataContext);
  const {
    selected: { blogId },
  } = state;

  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBlogDetails = async () => {
    try {
      const { data } = await getBlogById(blogId);
      setBlog(data.blog);
      // console.log(data.blog);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchBlogDetails().finally(() => setLoading(false));
  }, [blogId]);

  if (loading) {
    return (
      <Layout>
        <div className="container">Loading...</div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="container">Error: {error}</div>
      </Layout>
    );
  }
  return (
    <div className="row">
      {blog && (
        <div className="col-md-12">
          <div className="mb-4">
            <img
              className="img-fluid rounded"
              src={`${blog.pic_folder}/${blog.pic_file_name}`}
              alt={blog.name}
            />
          </div>
          <h3>
            <span className="mb-2">{blog.blog_title}</span>
          </h3>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: blog.blog_text,
              }}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default BlogDetails;
