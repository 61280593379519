import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/festivals.css";
import { getRecentBlogs } from "../../apis/blogApis";
import { DataContext } from "../../contexts/dataContext";
import { Link } from "react-router-dom";

const RecentBlogs = () => {
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { setSelectedBlog } = useContext(DataContext);

  const fetchRecentBlogs = async () => {
    try {
      const { data } = await getRecentBlogs();
      // console.log(data.recentBlogs);
      setRecentBlogs(data.recentBlogs);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchRecentBlogs().finally(() => setLoading(false));
  }, []);

  return (
    <div className="col">
      <div className="card shadow custom-card">
        <div className="card-header text-center">
          <span>Recent Blogs</span>
        </div>
        <div className="card-body">
          <div>
            <ul className="list-unstyled mb-0">
              {recentBlogs &&
                recentBlogs.map((blog) => (
                  <li key={blog.id}>
                    <Link
                      // type="button"
                      className="btn btn-link btn-link-sm custom-link"
                      onClick={() => setSelectedBlog(blog.id)}
                    >
                      {blog.blog_title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentBlogs;
