import React, { useEffect, useState, useContext } from "react";
import DestinationCard from "./destinationCard";
import { DataContext } from "../../contexts/dataContext";
import {
  getAllDestinations,
  getDestinationThemes,
} from "../../apis/destinationApis";
import "../../assets/styles/destinations.css";
import { Link } from "react-router-dom";

const Destinations = () => {
  const { state } = useContext(DataContext);
  // console.log(state);

  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(6); // Show 6 items initially
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [filteredDestinations, setFilteredDestinations] = useState([]);

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 6); // Show 6 more items
  };

  const fetchDestinationThemes = async () => {
    try {
      const { data } = await getDestinationThemes();
      setThemes(data.themes);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  const fetchDestinations = async () => {
    try {
      const { data } = await getAllDestinations();
      setDestinations(data.destinations);
      setFilteredDestinations(data.destinations);
      // console.log(data.destinations);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDestinationThemes();
    fetchDestinations().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (selectedTheme === 0) {
      setFilteredDestinations(destinations);
      return;
    }
    setFilteredDestinations(
      destinations.filter(
        (destination) => destination.theme_id === selectedTheme
      )
    );
  }, [selectedTheme]);

  // const setTheme = (themeId) => {
  //   setSelectedTheme(themeId);
  //   console.log("Selected theme:", themeId);
  //   setFilteredDestinations(
  //     destinations.filter(
  //       (destination) => destination.theme_id === selectedTheme
  //     )
  //   );
  //   console.log("Filtered destinations:", filteredDestinations);
  // };

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  return (
    <div>
      <h1 className="text-center mb-2">Destinations</h1>
      <ul className="nav nav-pills theme-pills" id="theme-pills">
        <li className="nav-item" key="0">
          <Link
            className="nav-link"
            data-filter="All"
            onClick={() => setSelectedTheme(0)}
          >
            Show All
          </Link>
        </li>
        {themes &&
          themes.map((theme) => (
            <li className="nav-item" key={theme.theme_id}>
              <Link
                className="nav-link"
                data-filter={`.${theme.name}`}
                onClick={() => setSelectedTheme(theme.theme_id)}
              >
                {theme.name}
              </Link>
            </li>
          ))}
      </ul>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {filteredDestinations.slice(0, visible).map((destination) => (
          <DestinationCard key={destination.id} {...destination} />
        ))}
        {visible < filteredDestinations.length && (
          <div className="text-center w-100">
            <Link onClick={loadMore}>Load More</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Destinations;
