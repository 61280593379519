import Layout from "../components/layout/layout";
import MonthList from "../components/helpers/monthList";
import DistrictList from "../components/helpers/districtList";
import { useContext } from "react";
import { DataContext } from "../contexts/dataContext";
import EventOfTheMonth from "../components/events/eventOfTheMonth";
import EventList from "../components/events/eventList";
import EventDetails from "../components/events/eventDetails";

const EventsHome = () => {
  const { state } = useContext(DataContext);
  // console.log(state);
  const {
    views: { eventOfTheMonth, eventList, eventDetails },
  } = state;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Layout>
      <section className="container my-5">
        <div className="row g-4">
          <div className="col-md-8">
            {eventOfTheMonth && <EventOfTheMonth />}
            {eventList && <EventList />}
            {eventDetails && <EventDetails />}
          </div>
          <div className="col-md-4">
            <MonthList view="event" />
            <DistrictList view="event" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EventsHome;
