import React, { useEffect, useState, useContext } from "react";
import Layout from "../layout/layout";
import { getFestivalById } from "../../apis/festivalApis";
import { DataContext } from "../../contexts/dataContext";
import { displayJSONInfo } from "../utils/displayJSONInfo";

const FestivalDetails = () => {
  const { state } = useContext(DataContext);
  const {
    selected: { festivalId },
  } = state;

  const [festival, setFestival] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFestivalDetails = async () => {
    try {
      const { data } = await getFestivalById(festivalId);
      setFestival(data.festival);
      // console.log(data.festival);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchFestivalDetails().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Layout>
        <div className="container">Loading...</div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="container">Error: {error}</div>
      </Layout>
    );
  }
  return (
    <div className="row">
      {festival && (
        <div className="col-md-12">
          <div className="mb-4">
            <img
              className="img-fluid rounded"
              src={`${festival.pic_folder}/${festival.pic_file_name}`}
              alt={festival.name}
            />
          </div>
          <h3>
            <span className="mb-2">{festival.fest_name}</span>
          </h3>
          <ul>
            <li className="li-gap">
              <span className="li-10em">When is it happening:</span>{" "}
              {festival.fest_dates}
            </li>
            <li className="li-gap">
              <span className="li-10em">Where is it happening:</span>{" "}
              {festival.location}
            </li>
            <li className="li-gap">
              <span className="li-10em">Main Attraction: </span>
              {festival.main_attraction}
            </li>
          </ul>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: festival.short_desc,
              }}
            />
          </p>
          <p>
            <div>
              {festival &&
                festival.general_info &&
                displayJSONInfo(festival.general_info)}
            </div>
          </p>
        </div>
      )}
    </div>
  );
};

export default FestivalDetails;
