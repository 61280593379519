import React, { useContext, useEffect, useState } from "react";
import { getDistricts } from "../../apis/destinationApis";
import "../../assets/styles/festivals.css";
import { DataContext } from "../../contexts/dataContext";
import { Link } from "react-router-dom";

const DistrictList = ({ view }) => {
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let cardHeader = "";
  if (view === "event") {
    cardHeader = "Show Events In...";
  } else if (view === "festival") {
    cardHeader = "Show Festivals In...";
  }
  const { setSelectedDistrict } = useContext(DataContext);

  const fetchDistricts = async () => {
    try {
      const { data } = await getDistricts();
      // console.log(data.districts);
      setDistricts(data.districts);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDistricts().finally(() => setLoading(false));
  }, []);

  return (
    <div className="col">
      <div className="card shadow custom-card">
        <div className="card-header text-center">
          <span>{cardHeader}</span>
        </div>
        <div className="card-body">
          <div>
            <ul className="list-unstyled mb-0">
              {districts.map((district) => (
                <li key={district.id}>
                  <Link
                    // type="button"
                    className="btn btn-link btn-link-sm custom-link"
                    onClick={() =>
                      setSelectedDistrict(
                        district.id,
                        district.district_name,
                        view
                      )
                    }
                  >
                    {district.district_name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistrictList;
