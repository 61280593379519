import React, { useState } from "react";
import Layout from "../components/layout/layout";
import { addInquiry } from "../apis/contact";

const initializeForm = {
  first_name: "",
  last_name: "",
  email_addr: "",
  phone: "",
  tour_operator: "no",
  message: "",
};

const TourPackages = () => {
  const [values, setValues] = useState(initializeForm);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your logic here to handle form submission
    // console.log("Form submitted:", values);
    // validate the form
    if (
      !values.first_name ||
      !values.last_name ||
      !values.email_addr ||
      !values.phone ||
      !values.tour_operator ||
      !values.message
    ) {
      return;
    }
    try {
      const { data } = await addInquiry(values);
      if (data.success) {
        setSuccess(
          "Thank you for your inquiry. We will get in touch with you soon."
        );
      } else {
        setError("There was a problem submitting your inquiry.");
      }
    } catch (error) {
      console.log(error);
    }
    // Reset form fields
    setValues(initializeForm);
  };

  return (
    <Layout>
      <div className="container my-5">
        <section className="mb-5">
          <div className="col-xl-10">
            <h1>
              Explore Kerala with private guides and a tailor-made itinerary.
            </h1>
            <p className="lead mb-0">
              Keralatour.com has been in the tourism business for more than 3
              decades offering custom private guided tours.
            </p>
          </div>
        </section>

        <section className="mt-5">
          <h3 className="display-6 app-text-custom2">Why Keralatour?</h3>
          <p className="lead text-secondary">
            Our tours are designed to give you the best of Kerala, with top
            guides, vetted hotels, and exceptional experiences.
          </p>
          <ul className="list-group mt-4">
            <li className="py-2 custom-bullet">
              <span className="text-dark">
                Your own private guide & vehicle
              </span>
            </li>
            <li className="py-2 custom-bullet">
              <span className="text-dark">
                An itinerary tailor-made for you by Destination Experts
              </span>
            </li>
            <li className="py-2 custom-bullet">
              <span className="text-dark">
                Extraordinary experiences, vetted hotels, hidden gems
              </span>
            </li>
            <li className="py-2 custom-bullet">
              <span className="text-dark">24/7 Support</span>
            </li>
          </ul>
        </section>

        <section className="pt-0 pt-lg-5">
          <div className="container">
            <div className="row g-4 g-lg-5 align-items-top">
              <div className="col-lg-6 ">
                <h3>Some of our popular tour packages include:</h3>
                <ul className="list-group mt-4">
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Holistic Tour</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Mystic Tour</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Festivals Delight</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Malabar Tour</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Hermitage Tour</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Palmgrove Tour</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Adventure Tour</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Eternal Bliss</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Monsoon Hangama</span>
                  </li>
                  <li className="py-2 custom-bullet">
                    <span className="text-dark">Vanchiyatra</span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 mx-auto">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="alert alert-success" role="alert">
                    {success}
                  </div>
                )}
                <h2 className="text-center">Send us a message</h2>
                <form onSubmit={handleSubmit}>
                  <div className="card bg-light p-4">
                    <div className="card-body p-0">
                      <div className="row g-4">
                        <div className="col-md-6">
                          <label className="form-label">First name *</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => onChange(e)}
                            id="first_name"
                            name="first_name"
                            value={values.first_name}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Last name *</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => onChange(e)}
                            id="last_name"
                            name="last_name"
                            value={values.last_name}
                            required
                          />
                        </div>
                        <div className="col-6">
                          <label className="form-label">Email address *</label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={(e) => onChange(e)}
                            id="email_addr"
                            name="email_addr"
                            value={values.email_addr}
                            required
                          />
                        </div>
                        <div className="col-6">
                          <label className="form-label">Mobile number *</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => onChange(e)}
                            id="phone"
                            name="phone"
                            value={values.phone}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <label className="form-label">
                            Are you a tour operator? *
                          </label>
                          <input
                            type="radio"
                            className="form-check-input mx-2"
                            id="yes"
                            name="tour_operator"
                            value="yes"
                            onChange={(e) => onChange(e)}
                            checked={values.tour_operator === "yes"}
                          />
                          <label className="form-check-label" htmlFor="yes">
                            Yes
                          </label>
                          <input
                            type="radio"
                            className="form-check-input mx-2"
                            id="no"
                            name="tour_operator"
                            value="no"
                            onChange={(e) => onChange(e)}
                            checked={values.tour_operator === "no"}
                          />
                          <label className="form-check-label" htmlFor="no">
                            No
                          </label>
                        </div>
                        <div className="col-12">
                          <label className="form-label">Message *</label>
                          <textarea
                            className="form-control"
                            rows="3"
                            onChange={(e) => onChange(e)}
                            id="message"
                            name="message"
                            value={values.message}
                          ></textarea>
                        </div>
                        <div className="col-12 form-check ms-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            required
                          />
                          <label
                            className="form-check-label"
                            for="exampleCheck1"
                          >
                            By submitting this form you agree to our terms and
                            conditions.
                          </label>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn custom-button mb-0"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TourPackages;
