import { useEffect, useState } from "react";
import { getEventOfTheMonth } from "../../apis/eventApis";
import { displayJSONInfo } from "../utils/displayJSONInfo";

const EventOfTheMonth = () => {
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchEventOfTheMonth = async () => {
    try {
      const { data } = await getEventOfTheMonth();
      setEvent(data.event);
      // console.log(data.event);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEventOfTheMonth().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  if (event === null || event === undefined) {
    return (
      <div className="text-center p-5">
        <h1 className="display-7 text-secondary">
          No Events Found for the Current Month
        </h1>
        <p className="lead text-muted">
          We are in the process of updating the latest events. Please check back
          later.
        </p>
      </div>
    );
  }

  return (
    <div className="row">
      {event && (
        <div className="col-md-12">
          <div className="mb-4">
            <img
              className="img-fluid rounded"
              src={`${event.pic_folder}/${event.pic_file_name}`}
              alt={event.name}
            />
          </div>
          <h3>
            <span className="mb-2">{event.fest_name}</span>
          </h3>
          <ul>
            <li className="li-gap">
              <span className="li-10em">When is it happening:</span>{" "}
              {event.fest_dates}
            </li>
            <li className="li-gap">
              <span className="li-10em">Where is it happening:</span>{" "}
              {event.location}
            </li>
            <li className="li-gap">
              <span className="li-10em">Main Attraction: </span>
              {event.main_attraction}
            </li>
          </ul>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: event.short_desc,
              }}
            />
          </p>
          <p>
            <div>
              {event &&
                event.general_info &&
                displayJSONInfo(event.general_info)}
            </div>
          </p>
        </div>
      )}
    </div>
  );
};

export default EventOfTheMonth;
