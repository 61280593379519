import "../../assets/styles/festivals.css";
import React, { useContext } from "react";
import { DataContext } from "../../contexts/dataContext";
import { Link } from "react-router-dom";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MonthList = ({ view }) => {
  const { setSelectedMonth } = useContext(DataContext);
  let cardHeader = "";
  if (view === "event") {
    cardHeader = "Show Events In...";
  } else if (view === "festival") {
    cardHeader = "Show Festivals In...";
  }

  return (
    <div className="col">
      <div className="card shadow custom-card">
        <div className="card-header text-center">
          <span>{cardHeader}</span>
        </div>
        <div className="card-body">
          <div>
            <ul className="list-unstyled mb-0">
              {monthNames.map((month, index) => (
                <li key={index}>
                  <Link
                    // type="button"
                    className="btn btn-link btn-link-sm custom-link"
                    onClick={() => setSelectedMonth(month, view)}
                  >
                    {month}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthList;
