import React from "react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import { getEventsByDistrict, getEventsByMonth } from "../../apis/eventApis";
import { Link } from "react-router-dom";
import { formatIsoDate } from "../utils/dateFunctions";

const EventList = () => {
  const { state, setSelectedEvent } = useContext(DataContext);
  // console.log(data);
  const {
    selected: { districtId, districtName, month },
  } = state;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchEvents = async () => {
    let response = null;
    try {
      if (districtId) {
        response = await getEventsByDistrict(districtId);
      } else if (month) {
        response = await getEventsByMonth(month);
      }
      // console.log(response);
      setEvents(response.data.events);
      // console.log(events);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setError("error fetching data" + error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchEvents().finally(() => setLoading(false));
  }, [state]);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  if (events && events.length === 0) {
    return (
      <div className="text-center p-5">
        <h1 className="display-7 text-secondary">No Events Found</h1>
        <p className="lead text-muted">Please check back later for updates.</p>
      </div>
    );
  }

  return (
    <div className="row g-2">
      <h2>
        Events in {month} {districtName}
      </h2>
      {events &&
        events.map((event) => (
          <article className="col-12 mb-0" key={event.id}>
            <div className="card mb-4 rounded-5 shadow-sm h-shadow custom-card-transition">
              <div className="row no-gutters">
                <div className="col-md-4 mb-md-0 d-flex align-items-center justify-content-center">
                  <img
                    src={`${event.pic_folder}/${event.pic_file_name}`}
                    alt={event.event_name}
                    className="img-thumbnail rounded-3 object-cover border-0"
                  />
                </div>
                <div className="col-md-8 p-2">
                  <div className="card-body">
                    <Link onClick={() => setSelectedEvent(event.id)}>
                      {event.event_name}
                    </Link>

                    <p className="card-text">
                      {event.from_dt === event.to_dt
                        ? formatIsoDate(event.from_dt)
                        : `${formatIsoDate(event.from_dt)} - ${formatIsoDate(
                            event.to_dt
                          )}`}
                    </p>
                    <p className="card-text">
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: event.short_desc,
                        }}
                      />
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="btn-group">
                        <button
                          onClick={() => setSelectedEvent(event.id)}
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
    </div>
  );
};

export default EventList;
