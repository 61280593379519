import Layout from "../components/layout/layout";
import Carousel from "../components/helpers/carousel";
import "../assets/styles/home.css";
import { getPopularDestinations } from "../apis/destinationApis";
import { useEffect, useState } from "react";
import DestinationCard from "../components/destinations/destinationCard";

const SearchForm = () => (
  // <form className="search-form">
  //   <input type="text" placeholder="Search..." />
  //   <button type="submit">Search</button>
  // </form>
  <div className="container">
    <div className="row justify-content-md-center">
      <div className="col">
        <form className="search-form w-25">
          <div className="input-group">
            <input
              className="form-control form-control-lg"
              type="text"
              name="destination"
              id="destination"
              placeholder="Where to..."
            />
            <span className="input-group-btn">
              <button
                type="button"
                name="search"
                id="search"
                className="btn btn-lg btn-secondary"
              >
                Search
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
);

const Home = () => {
  const [popularDestinations, setPopularDestinations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPopularDestinations = async () => {
    try {
      const { data } = await getPopularDestinations();
      setPopularDestinations(data.destinations);
      // console.log(data.destinations);
    } catch (error) {
      setError("error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchPopularDestinations().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Layout>
        <div className="container">Loading...</div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="container">Error: {error}</div>
      </Layout>
    );
  }

  const images = [
    "/assets/images/home/hero-4.jpg",
    "/assets/images/home/hero-5.jpg",
    "/assets/images/home/hero-6.jpg",
    // Add more image URLs as needed
  ];

  return (
    <Layout>
      <main>
        <section className="hero-home">
          <div className="carousel-container">
            <Carousel images={images} />
          </div>
        </section>

        <section className="container my-5">
          <h1 className="text-center mb-5">Featured Destinations</h1>
          <div className="row row-cols-1 row-cols-md-3 g-4">
            {popularDestinations.map((destination) => (
              <DestinationCard key={destination.id} {...destination} />
            ))}
          </div>
        </section>

        <section className="container my-5" id="why-us">
          <h2 className="pb-2 border-bottom">Why Choose Us</h2>
          <div className="row g-4 py-3 row-cols-1 row-cols-lg-3">
            <div className="feature col">
              <h3>Personalized Itineraries</h3>
              <p>
                Our team of experts will create a custom itinerary tailored to
                your preferences and budget. Your dream vacation is just a few
                clicks away!
              </p>
            </div>
            <div className="feature col">
              <h3>Experienced Guides</h3>
              <p>
                Our knowledgeable guides will provide you with an authentic and
                immersive travel experience.
              </p>
            </div>
            <div className="feature col">
              <h3>Hassle-free Booking</h3>
              <p>
                We handle all the logistics, so you can focus on enjoying your
                trip. Book your next adventure with us today!
              </p>
            </div>
          </div>
        </section>

        <section className="container my-5" id="testimonials">
          <h2 className="pb-2 border-bottom">Testimonials</h2>
          <div className="row g-4 py-3 row-cols-1 row-cols-lg-3">
            <div className="testimonial col">
              <div className="card">
                <div className="card-body">
                  <blockquote className="blockquote mb-0">
                    <p>
                      "I had a wonderful experience exploring Kerala with the
                      help of Mr Varier @Keralatour. I highly recommend booking
                      your next trip with them!"
                    </p>
                    <footer className="blockquote-footer">
                      <cite>Mr. Sasi Tharoor</cite>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="testimonial col">
              <div className="card">
                <div className="card-body">
                  <blockquote className="blockquote mb-0">
                    <p>
                      "Mr. Varier is to Tourism what Mr. David Beckham is to
                      football. I highly recommend their services to anyone
                      looking for a memorable travel experience!"
                    </p>
                    <footer className="blockquote-footer">
                      <cite>Mr. David Garson - Award-winning writer</cite>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="testimonial col">
              <div className="card">
                <div className="card-body">
                  <blockquote className="blockquote mb-0">
                    <p>
                      "Mr Varier is the very knowledgeable about Kerala, it's
                      history and culture. He is a great guide and a wonderful
                      person. I highly recommend him."
                    </p>
                    <footer className="blockquote-footer">
                      <cite>Mr. Rajagopal -Vice President, World Bank</cite>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Home;
