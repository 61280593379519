import React from "react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import { Link } from "react-router-dom";
import { getBlogsByCategory } from "../../apis/blogApis";

const BlogList = () => {
  const { state, setSelectedBlog } = useContext(DataContext);
  // console.log(data);
  const {
    selected: { blogCategoryId, blogCategory },
  } = state;

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchBlogs = async () => {
    let response = null;
    try {
      if (blogCategoryId) {
        response = await getBlogsByCategory(blogCategoryId);
      }
      // console.log(response.data.blogs);
      setBlogs(response.data.blogs);
      // console.log(blogs);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setError("error fetching data" + error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchBlogs().finally(() => setLoading(false));
  }, [state]);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  if (blogs && blogs.length === 0) {
    return (
      <div className="text-center p-5">
        <h1 className="display-7 text-secondary">
          No Blogs for the selected filter
        </h1>
        <p className="lead text-muted">
          We are in the process of updating the latest information. Please check
          back later.
        </p>
      </div>
    );
  }

  return (
    <div className="row g-2">
      <h2>Blogs in {blogCategory}</h2>
      {blogs &&
        blogs.map((blog) => (
          <article className="col-12 mb-0" key={blog.id}>
            <div className="card mb-4 rounded-5 shadow-sm h-shadow custom-card-transition">
              <div className="row no-gutters">
                <div className="col-md-4 mb-md-0 d-flex align-items-center justify-content-center">
                  <Link onClick={() => setSelectedBlog(blog.id)}>
                    <img
                      src={`${blog.pic_folder}/${blog.pic_file_name}`}
                      alt={blog.blog_title}
                      className="img-thumbnail rounded-3 object-cover border-0"
                    />
                  </Link>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <Link onClick={() => setSelectedBlog(blog.id)}>
                      {blog.blog_title}
                    </Link>
                    <p className="card-text">
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: blog.blog_desc,
                        }}
                      />
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="btn-group">
                        <button
                          onClick={() => setSelectedBlog(blog.id)}
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
    </div>
  );
};

export default BlogList;
