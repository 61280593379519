export function displayJSONInfo(info) {
  return (
    <div className="venue-details">
      {Object.entries(info).map(([key, value]) => (
        <div key={key}>
          <h6 className="mt-2 text-uppercase title-sidebox">{key}</h6>
          {typeof value === "object" && value !== null
            ? Object.entries(value).map(([subKey, subValue]) => (
                <p key={subKey}>
                  <strong>{subKey}: </strong>
                  {subValue}
                </p>
              ))
            : value}
        </div>
      ))}
    </div>
  );
}
