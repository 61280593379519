import apiHub from "./apiHub";

export async function getCultureSpotLight() {
  return await apiHub.get("/culture-spotlight");
}

export async function getAllCulture() {
  return await apiHub.get("/all-culture");
}

export async function getCultureByCategory(categoryId) {
  // console.log("getCultureByCategory called");
  return await apiHub.get("/culture-by-category", { params: { categoryId } });
}

export async function getCultureById(id) {
  return await apiHub.get("/culture", { params: { id } });
}

export async function getCultureCategories() {
  return await apiHub.get("/culture-categories");
}
