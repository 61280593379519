import apiHub from "./apiHub";

export async function getRecentBlog() {
  return await apiHub.get("/recent-blog");
}

export async function getRecentBlogs() {
  return await apiHub.get("/recent-blogs");
}

export async function getBlogById(blogId) {
  return await apiHub.get(`/blog?blogId=${blogId}`);
}

export async function getBlogCategories() {
  return await apiHub.get("/blog-categories");
}

export async function getBlogsByCategory(categoryId) {
  return await apiHub.get(`/blogs-by-category?categoryId=${categoryId}`);
}
