import axios from "axios";
// axios.defaults.withCredentials = true;

export default axios.create({
  // baseURL: "http://localhost:3000/api/v1",
  baseURL: "https://keralatour.com/api/v1",
  // baseURL:"http://ec2-34-221-200-247.us-west-2.compute.amazonaws.com:3000/api/v1",
  // baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});
