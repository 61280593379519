import FestivalList from "../components/festivals/festivalList";
import FestivalOfTheMonth from "../components/festivals/festivalOfTheMonth";
import FestivalDetails from "../components/festivals/fetivalDetails";
import Layout from "../components/layout/layout";
import MonthList from "../components/helpers/monthList";
import DistrictList from "../components/helpers/districtList";
import { useContext } from "react";
import { DataContext } from "../contexts/dataContext";

const FestivalsHome = () => {
  const { state } = useContext(DataContext);
  // console.log(state);
  const {
    views: { festivalOfTheMonth, festivalList, festivalDetails },
  } = state;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Layout>
      <section className="container my-5">
        <div className="row g-4">
          <div className="col-md-8">
            {festivalOfTheMonth && <FestivalOfTheMonth />}
            {festivalList && <FestivalList />}
            {festivalDetails && <FestivalDetails />}
          </div>
          <div className="col-md-4">
            <MonthList view="festival" />
            <DistrictList view="festival" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FestivalsHome;
