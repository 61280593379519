import React from "react";
import Layout from "../components/layout/layout";

const FAQ = () => {
  const faqData = [
    {
      question: "How do I book a trip?",
      answer:
        "You can book your trip by contacting our reservations team via phone or email (contact information will be displayed here).",
    },
    {
      question: "What forms of payment do you accept?",
      answer:
        "We accept all major credit cards, debit cards, and secure online payments.",
    },
    {
      question: "Can I modify or cancel my reservation?",
      answer:
        "Yes, you can modify or cancel your reservation depending on the specific policy of your chosen travel package.  Please refer to the terms and conditions during booking for details.",
    },
    {
      question: "What documents do I need to travel?",
      answer:
        "The required documents will depend on your destination and nationality.  We recommend checking the official government website of your destination for the latest requirements.  Generally, a valid passport is required for most international travel.",
    },
    {
      question: "Do I need a visa?",
      answer:
        "Visa requirements vary depending on your nationality and destination.  We recommend checking the official embassy or consulate website of your destination for the latest visa information.",
    },
    {
      question: "What should I pack?",
      answer:
        "We recommend packing light, comfortable clothing appropriate for the climate of your destination.  Be sure to check the weather forecast before you go.  We can also provide you with a packing list specific to your destination upon request.",
    },
    {
      question: "Is it safe to travel to my destination?",
      answer:
        "Yes. Kerala is a very safe place to travel. Our guides will ensure your safety and comfort throughout your trip. We recommend checking the latest travel advisories from your government before you travel.",
    },
    {
      question: "What is included in my trip package?",
      answer:
        "This will vary depending on the specific trip you book.  Please refer to the trip itinerary for a detailed breakdown of what's included.",
    },
    {
      question: "Still have questions?",
      answer:
        "If you can't find the answer to your question here, please don't hesitate to contact us by email at info@keralatour.com.  We're happy to help!",
    },
  ];

  return (
    <Layout>
      <div className="container my-5">
        <section>
          <h1>Frequently Asked Questions</h1>
          {faqData.map((item, index) => (
            <div key={index}>
              <h5>{item.question}</h5>
              <p>{item.answer}</p>
            </div>
          ))}
        </section>
      </div>
    </Layout>
  );
};

export default FAQ;
